import * as React from "react"
import useStoryblok from "../lib/storyblok"
import { useContext, useEffect } from "react"
import { CurrentPageContext } from "../context"
import Seo from "../components/services/seo"
import DynamicComponent from "../components/services/dynamicComponent"

const Page = ({ pageContext, location }) => {
  let story = pageContext.story
  story = useStoryblok(story, location)

  const { currentPage, setCurrentPage} = useContext(CurrentPageContext);
  useEffect(() => {
    // story.full_slug="interior-design/londor-architecture";
    // if full slug contains /interior-design/ then set additional breadcrumb
    if (story.full_slug && story.full_slug != 'interior-design/' && story.full_slug.includes('interior-design/')) {
      setCurrentPage([{
        slug: '/interior-design',
        name: 'Services'
      },
      {
        slug: '',
        name: story.name
      }])
    } else {
      setCurrentPage([{
        slug: '',
        name: story.name
      }])  
    }
  }, [setCurrentPage, story.name])

  let allImages = [];
  const components = story.content.body.map(blok => {
    if (blok.component == "ContentBlock"){
      if (blok.image && blok.image.filename){
        allImages.push(blok.image);
      }
      if (blok.image_two && blok.image_two.filename){
        allImages.push(blok.image_two);
      }
    }
    if (blok.component == "infobanners"){
      blok.items.map(item => {
        if (item.image && item.image.filename){
          allImages.push(item.image);
        }        
        return item;
      })
    }
    if (blok.component == "teaserscroller"){
      blok.items.map(item => {
        if (item.image && item.image.filename){
          allImages.push(item.image);
        }        
        return item;
      })
    }
    if (blok.component == "teaser"){
      if (blok.image && blok.image.filename){
        allImages.push(blok.image);
      }
    }
    if (blok.component == "masonryitems"){
      allImages = allImages.concat(blok.images);
    }
    return (<DynamicComponent blok={blok} key={blok._uid} />)
  })

  allImages = allImages.map(item => {
    return {
      "@type": "imageObject",
      "url": item.filename.replace('//a.storyblok.com/f/139398', '//assets.blackandmilk.co.uk')
    }
  });

  const siteData = pageContext.siteInfo;
  const schema = story.content.schema_markup;

  return (
  <>
    <Seo title={story.content.title_meta} description={story.content.description_meta} schemaMarkup={schema} url={location.href} />
    { components }
  </>
)}

export default Page
